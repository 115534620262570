import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AmountService {

  constructor() { }

  private amountSource = new BehaviorSubject<string>('0,00');
  currentAmount$ = this.amountSource.asObservable();

  updateAmount(amount: string) {
    this.amountSource.next(amount);
  }
}
