<div class="component__container">
    <div class="display__container">
        <div class="display__amount-box">
            <span #amountSpan>
                {{ amount }} €
            </span>
        </div>
    </div>
    <small *ngIf="maxDigits" class="message__error">L'importo è troppo alto.</small>
    <div class="keypad__container">
        <button class="keypad__button" (click)="addDigit('1')">1</button>
        <button class="keypad__button" (click)="addDigit('2')">2</button>
        <button class="keypad__button" (click)="addDigit('3')">3</button>
        <button class="keypad__button keypad__button--action" (click)="clearAmount()">
            <img class="keypad__button--image" src="assets/icons/cancel.svg" alt="Cancel">
        </button>
        <button class="keypad__button" (click)="addDigit('4')">4</button>
        <button class="keypad__button" (click)="addDigit('5')">5</button>
        <button class="keypad__button" (click)="addDigit('6')">6</button>
        <button class="keypad__button keypad__button--action" (click)="removeDigit()">
            <img class="keypad__button--image" src="assets/icons/delete.svg" alt="Backspace">
        </button>
        <button class="keypad__button" (click)="addDigit('7')">7</button>
        <button class="keypad__button" (click)="addDigit('8')">8</button>
        <button class="keypad__button" (click)="addDigit('9')">9</button>
        <button class="keypad__button keypad__button--action" (click)="confirmAmount()">
            <img class="keypad__button--image" src="assets/icons/confirm.svg" alt="Confirm">
        </button>
        <button class="keypad__button keypad__button--zero" (click)="addDigit('0')">0</button>
    </div>
</div>