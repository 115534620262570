import { Component, ElementRef, EventEmitter, Output, ViewChild } from '@angular/core';
import { REGEX_COMMA_SEPARATION, REGEX_NON_DIGITS } from '../../constants/rejex.costants';

@Component({
  selector: 'app-keypad',
  templateUrl: './keypad.component.html',
  styleUrl: './keypad.component.scss'
})
export class KeypadComponent {
  @Output() amountSelected = new EventEmitter<string>();

  amount: string = "0,00";
  maxDigits = false;

  addDigit(digit: string) {
    if (!/^\d$/.test(digit)) return; // Assicura che il carattere sia un numero
    if (this.amount.length > 10) {
      this.maxDigits = true
      return;
    } else {
      this.maxDigits = false
    }
    // Rimuove la virgola e formatta il numero come stringa
    let plainNumber = this.amount.replace(REGEX_NON_DIGITS, '');


    // Aggiunge la nuova cifra
    plainNumber = plainNumber + digit;


    // Formatta il numero
    this.amount = this.formatNumber(plainNumber);
  }

  confirmAmount() {
    if (this.amount !== "0,00") {
      this.amountSelected.emit(this.amount)
    }
  }

  removeDigit() {
    this.maxDigits = false

    // Rimuove la virgola e formatta il numero come stringa
    let plainNumber = this.amount.replace(REGEX_NON_DIGITS, '');

    // Rimuove l'ultima cifra
    plainNumber = plainNumber.slice(0, -1);

    // Se il numero è vuoto, imposta a '0'
    if (plainNumber.length === 0) {
      plainNumber = '0';
    }

    // Formatta il numero
    this.amount = this.formatNumber(plainNumber);
  }

  formatWithCommas(amount: string): string {
    const parts = amount.split(',');
    parts[0] = parts[0].replace(REGEX_COMMA_SEPARATION, '.');
    return parts.join(',');
  }


  formatNumber(plainNumber: string): string {
    // Rimuove gli zeri non necessari
    while (plainNumber.length > 1 && plainNumber[0] === '0') {
      plainNumber = plainNumber.slice(1);
    }

    // Se il numero ha meno di 3 cifre, aggiunge zeri all'inizio
    while (plainNumber.length < 3) {
      plainNumber = '0' + plainNumber;
    }

    // Inserisce la virgola
    const integerPart = plainNumber.slice(0, -2);
    const decimalPart = plainNumber.slice(-2);

    let formattedNumber = integerPart + ',' + decimalPart;

    // Mette il punto alle migliaia
    return this.formatWithCommas(formattedNumber);
  }


  clearAmount() {
    this.maxDigits = false
    this.amount = '0,00';
  }
}
